<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
      </div>
      <el-dialog title="新增审批" :visible.sync="dialogFormVisible" width="500px">
        <el-form ref="dialogForm" :model="dialogForm" label-width="120px" size="mini" :show-message="false">
          <el-form-item label="审批模块">
            <el-select
              v-model="dialogForm.perm_id"
              filterable
              placeholder="请选择审批模块"
              clearable
              @visible-change="getPermList($event)"
              @change="getPermId"
            >
              <el-option v-for="item in permList" :key="item.perm_id" :label="item.perm_name" :value="item.perm_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
          <el-button type="primary" @click="submit('dialogForm')" size="small">确 定</el-button>
        </div>
      </el-dialog>
      <el-row>
        <el-col :md="24">
          <el-table
            ref="multiTable"
            v-loading="loadFlag"
            :model="apprForm"
            :data="tableData"
            class="vd_table vg_cursor"
            @row-dblclick="dbClickJp"
            border
          >
            <el-table-column label="模块ID" :show-overflow-tooltip="true" prop="perm_id" />
            <el-table-column label="审批模块" :show-overflow-tooltip="true" prop="perm_name" />
          </el-table>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { permAPI } from '@api/modules/perm';
import { apprAPI } from '@api/modules/appr';
export default {
  name: 'ApprList',
  components: {},
  data() {
    return {
      apprForm: {
        perm_name: ''
      },
      dialogForm: {
        perm_name: null,
        perm_id: null
      },
      dialogFormVisible: false,
      permList: [],
      tableData: [],
      btn: {},
      loadFlag: true
    };
  },
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.getApprs();
    },
    // 获取permId
    getPermList(flag) {
      if (flag === true && this.permList.length === 0) {
        get(permAPI.getPermsLevel1)
          .then(res => {
            if (res.data.code === 0) {
              let permList = res.data.data;
              let temp = [];
              for (let i = 0; i < permList.length; i++) {
                if (permList[i].perm_id !== 120) {
                  temp.push(permList[i]);
                }
              }
              this.permList = temp;
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          });
      }
    },
    // 选中值
    getPermId() {
      this.permList.find(item => {
        if (item.perm_id === this.dialogForm.perm_id) {
          this.dialogForm.perm_name = item.perm_name;
        }
      });
    },
    // 获取审核列表
    getApprs() {
      get(apprAPI.getAllApprs)
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 新增
    addRow() {
      this.dialogFormVisible = true;
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.jump('/appr_edit', { perm_id: this.$route.query.perm_id, form_id: row.appr_id });
    },
    //提交表单
    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.saveInfo();
        } else {
          console.log('error submit!');
          return false;
        }
      });
    },
    // 保存
    saveInfo() {
      if (this.dialogForm.perm_id && this.dialogForm.perm_name) {
        post(apprAPI.addAppr, this.dialogForm)
          .then(res => {
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '添加成功'
              });
              this.jump('appr_edit', { perm_id: 107, form_id: res.data.data.id });
              this.dialogFormVisible = false;
              this.dialogForm.perm_name = '';
              this.getApprs();
            } else {
              let mg = res.data.msg;
              let er = 'error';
              this.$message({
                type: er,
                message: mg
              });
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          });
      } else {
        this.$message({ message: '至少选择一个审批模块', type: 'warning' });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_table {
  width: 20%;
}
</style>
